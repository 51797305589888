export default {
	adminMenu_general: "Generelt",
	adminMenu_professionalAutosignatures: "Autosignaturer",
	adminMenu_patientStates: "Borgerstatus",
	adminMenu_unprocessedPatients: "Borgere uden CPR opdatering",
	adminMenu_letterTemplates: "Brevskabeloner",
	adminMenu_pathwayTypes: "Forl\u00F8bstyper",
	adminMenu_financeConfiguration: "Konfiguration",
	adminMenu_financialAccounts: "Kontonumre (udg\u00E5et)",
	adminMenu_kleNumbers: "KLE-numre",
	adminMenu_subjectTemplates: "Korrespondanceemne",
	adminMenu_grant_offer_portal_suppliers: "Leverand\u00F8rer fra Tilbudsportalen",
	adminMenu_homeAdjustment: "Boligtilpasninger",
	adminMenu_suppliers: "Leverand\u00F8rer",
	adminMenu_logViewer: "Log viewer",
	adminMenu_locations: "Lokaler",
	adminMenu_mobileProfile: "Mobilkonfiguration",
	adminMenu_organizations: "Organisationer, brugere og sikkerhed",
	adminMenu_assignments: "Opgaver",
	adminMenu_assignment_pause_reasons: "Opgaver - \u00C5rsager til pause",
	adminMenu_paragraphs: "Paragraffer",
	adminMenu_physicalResources: "Ressourcer",
	adminMenu_physicalResourceTypes: "Ressourcetyper",
	adminMenu_citizenCompensationRate: "Satser",
	adminMenu_professionalLocks: "Se l\u00E5ste brugere",
	adminMenu_formDefinitions: "Skemaer",
	adminMenu_formDefinitions_new: "Skemaer new",
	adminMenu_professionalJobs: "Stillingsbetegnelser",
	adminMenu_sites: "Steder",
	adminMenu_hospitalStay: "Sygehusophold",
	adminMenu_tags: "Tags",
	adminMenu_shifts: "Vagter",
	adminMenu_programPathways: "Forl\u00F8b",
	adminMenu_catalogGrants: "Indsatskatalog",
	adminMenu_finance: "\u00d8konomi",
	adminMenu_payoutRecipients: "Udbetalingsmodtagere",
	adminMenu_payoutFinancialAccounts: "Kontonumre",
	adminMenu_calendar: "Kalender",
	adminMenu_eventTypes: "Aftaletyper",
	adminMenu_plannedGrantEventTypes: "Bes\u00F8gstyper",
	adminMenu_groupEventSubTypes: "Kalenderaftaletyper",
	adminMenu_groupEventRegistrationStatus: "Kalenderaftalestatus",
	adminMenu_groupRegisteredGrantStatuses: "Leveret ydelsesstatus (Aftaler)",
	adminMenu_registeredGrantStatuses: "Leveret ydelsesstatus (Bes\u00F8g)",
	adminMenu_groupPlannedGrantStatuses: "Planlagt ydelsesstatus (Aftaler)",
	adminMenu_plannedGrantStatuses: "Planlagt ydelsesstatus (Bes\u00F8g)",
	adminMenu_resourcePlannedGrantStatuses: "Planlagt ydelsesstatus (Ressourcer)",
	adminMenu_resourceEventTypes: "Medarbejderaftaletype",
	adminMenu_resourceEventStatuses: "Medarbejderaftalestatus",
	adminMenu_registrationStatus: "Registreringsstatus",
	adminMenu_calendarRoutes: "Ruter",
	adminMenu_contacts: "Eksterne kontakter",
	adminMenu_organizationContacts: "Kontaktorganisationer",
	adminMenu_organizationContactTypes: "Kontaktorganisationstyper",
	adminMenu_professionalContacts: "Kontaktpersoner",
	adminMenu_professionalContactTypes: "Kontaktpersontyper",
	adminMenu_relativeContactTypes: "P\u00E5r\u00F8rendetyper",
	adminMenu_medicin: "Medicin",
	adminMenu_internalDrugs: "Internt oprettede pr\u00E6parater",
	adminMenu_inventoryManagement: "Lagre",
	adminMenu_reports: "Rapporter",
	adminMenu_measurements: "M\u00E5linger",
	adminMenu_hclProducts: "Sundhedslogistik",
	adminMenu_hclHmiCatalogues: "HMI-kataloger",
	adminMenu_hclDepots: "Depoter",
	adminMenu_hclSuppliers: "Forhandlere",
	adminMenu_hclProductSuppliers: "Produktleverand\u00F8rer",
	adminMenu_hclLabelDesign: "Labeldesign",
	adminMenu_hclCars: "Biler",
	adminMenu_hclDrivingZones: "K\u00F8rselszoner",
	adminMenu_hclDeliveryTypes: "K\u00F8rselstyper",
	adminMenu_hclMinimumInventory: "Minimumsbeholdning",
	adminMenu_hclOpslag: "Opslag",
	adminMenu_hclAutomation: "Automatisering",
	adminMenu_hclProductItemsOwners: "Ejer",
	adminMenu_patientCategory: "Borgerkategorier",
	adminMenu_financeContactCreditors: "Kreditorer",
	adminMenu_reminders: "P\u00E5mindelser",
	adminMenu_tabletAppConfiguration: "Tablet app konfiguration",
	adminMenu_hclStockPlacements: "Lagerplacering",

	mainMenu_overview: "Overblik",
	mainMenu_citizens: "Borgere",
	mainMenu_activityList: "Aktivitetsliste",
	mainMenu_calendar: "Kalender",
	mainMenu_data: "Data",
	mainMenu_advise: "Advis",
	mainMenu_advise_inbox: "Advis indbakke",
	mainMenu_advise_draft: "Advis kladde",
	mainMenu_advise_outbox: "Advis afsendt",
	mainMenu_advise_archive: "Advis arkiv",
	mainMenu_waitingList: "Venteliste",
	mainMenu_distributionLists: "Fordelingslister",
	mainMenu_finance: "\u00d8konomi",
	mainMenu_finance_billingMunicipalList: "Takstopkr\u00e6vning",
	mainMenu_finance_billingMunicipalPlanning: "Planlagte takstopkr\u00E6vninger",
	mainMenu_finance_billingPatientList: "Borgeropkr\u00e6vning",
	mainMenu_finance_billingPatientPlanning: "Planlagte borgeropkr\u00e6vninger",
	mainMenu_finance_fkInvoiceActiveDocuments: "Fakturaer til behandling",
	mainMenu_finance_fkInvoiceAllDocuments: "Fakturafremsøgning",
	mainMenu_finance_payoutTransactions: "Udbetalinger",
	mainMenu_finance_citizenCompensationGroups: "Arbejdsdus\u00F8r",
	mainMenu_finance_exportTransactionGroups: "Eksportlog - Opkr\u00e6vninger",
	mainMenu_finance_payoutExportLogs: "Eksportlog - Udbetalinger",
	mainMenu_effectuation: "Medicinh\u00e5ndtering",
	mainMenu_effectuation_internalEffectuations: "Udleveringsliste",
	mainMenu_effectuation_drugInventoryItems: "Lagerbeholdning",
	mainMenu_effectuation_labelPrint: "Print af labels",
	mainMenu_depot: "Depot",
	mainMenu_depot_repairsView: "Til reparation",

	patientMenu_overview: "Overblik",
	patientMenu_citizenPathway: "Borgerforl\u00f8b",
	patientMenu_calendar: "Kalender",
	patientMenu_correspondence: "Korrespondance",
	patientMenu_correspondence_inbox: "Indbakke",
	patientMenu_correspondence_draft: "Kladde",
	patientMenu_correspondence_outbox: "Sendt",
	patientMenu_correspondence_deleted: "Slettet",
	patientMenu_citizenData: "Data",
	patientMenu_medicine: "Medicin",
	patientMenu_medicine_current: "Aktuel medicin",
	patientMenu_medicine_dispensing: "Dispensering",
	patientMenu_medicine_handout: "Medicinliste Udlevering",
	patientMenu_medicine_nursing: "Medicinliste Sygepleje",
	patientMenu_medicine_dispensation: "PN Medicin",
	patientMenu_medicine_history: "Historik",
	patientMenu_finance: "\u00D8konomi",
	patientMenu_finance_overview: "Alle ydelser",
	patientMenu_hclMatching: "Udl\u00e5n",
	patientMenu_hclMatching_current: "Aktuelt",
	patientMenu_hclMatching_history: "Historik",
	patientMenu_eop: "Effect & Progression",

	test_testTranslation: "Test Translation"
};
