export default [
	{
		name: "hcl-matching",
		pathPrefix: "/hcl-matching",
		pattern: /(^\/?hcl-matching\/(.*)|^\/?hcl-matching)/
	},
	{
		name: "hcl-logistics",
		pathPrefix: "/hcl-logistics",
		pattern: /(^\/?hcl-logistics\/(.*)|^\/?hcl-logistics)/
	},
	{
		name: "hcl-administration",
		pathPrefix: "/hcl-administration",
		pattern: /(^\/?hcl-administration\/(.*)|^\/?hcl-administration)/
	},
	{
		name: "medicine-ui",
		pathPrefix: "/medicine-ui",
		pattern: /(^\/?medicine-ui\/(.*)|^\/?medicine-ui)/
	},
	{
		name: "tablet-app",
		pathPrefix: "/tablet-app-ui/",
		pattern: /(^\/?tablet-app-ui\/(.*)|^\/?tablet-app-ui)/
	},
	{
		name: "patient-ui",
		pathPrefix: "/citizen/administration",
		pattern: /(^\/?citizen\/administration\/(.*)|^\/?citizen\/administration)/
	},
	{
		name: "finance-payouts",
		pathPrefix: "/finance-payouts",
		pattern: /(^\/?finance-payouts\/(.*)|^\/?finance-payouts)/
	},
	{
		name: "finance-account",
		pathPrefix: "/finance-account",
		pattern: /(^\/?finance-account\/(.*)|^\/?finance-account)/
	},
	{
		name: "sts-cdi-ui",
		pathPrefix: "/sts-cdi",
		pattern: /(^\/?sts-cdi\/(.*)|^\/?sts-cdi)/
	},
	{
		name: "mobile-profile-ui",
		pathPrefix: "/mobile-profile-ui",
		pattern: /(^\/?mobile-profile-ui\/(.*)|^\/?mobile-profile-ui)/
	},
	{
		name: "sts-classifications",
		pathPrefix: "/sts-classifications",
		pattern: /(^\/?sts-classifications\/(.*)|^\/?sts-classifications)/
	},
	{
		name: "fk-distribution-objects-ui",
		pathPrefix: "/fk-distribution-objects",
		pattern: /(^\/?fk-distribution-objects\/(.*)|^\/?fk-distribution-objects)/
	},
	{
		name: "fk-invoice-ui",
		pathPrefix: "/fk-invoice",
		pattern: /(^\/?fk-invoice\/(.*)|^\/?fk-invoice)/
	},
	{
		name: "finance-contacts",
		pathPrefix: "/finance-contacts",
		pattern: /(^\/?finance-contacts\/(.*)|^\/?finance-contacts)/
	},
	{
		name: "reminders-ui",
		pathPrefix: "/reminders",
		pattern: /(^\/?reminders\/(.*)|^\/?reminders)/
	},
	{
		name: "rbac-ui",
		pathPrefix: "/rbac-ui",
		pattern: /(^\/?rbac-ui\/(.*)|^\/?rbac-ui)/
	},
	{
		name: "seb-client",
		pathPrefix: "/seb-client",
		pattern: /(^\/?seb-client\/(.*)|^\/?seb-client)/
	},
	{
		name: "schema-designer",
		pathPrefix: "/schema-designer",
		pattern: /(^\/?schema-designer\/(.*)|^\/?schema-designer)/
	},
	{
		name: "nexus",
		pathPrefix: "",
		pattern: /^\/?(.*)/
	}
];
