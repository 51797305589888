import messagesDa from "./messages.da";
import messagesNb from "./messages.nb";

export default class Translator {
	constructor(locale) {
		this.locale = locale;
	}

	translate(translationLabel) {
		switch (this.locale) {
		case "nb_no":
			if (messagesNb[translationLabel]) {
				return messagesNb[translationLabel];
			}
			return this.returnDanishOrLabel(translationLabel);
		case "da_dk":
		default:
			return this.returnDanishOrLabel(translationLabel);
		}
	}

	returnDanishOrLabel(translationLabel) {
		if (messagesDa[translationLabel]) {
			return messagesDa[translationLabel];
		}
		return translationLabel;
	}
}
